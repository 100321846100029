/*--

/* start editing from here */

.login{
	/*background: linear-gradient(to right bottom, #1cc7d0 ,#013ca6, #013ca6,#1cc7d0);*/
	/* background: #cd1f46; */
	margin:0;
	height:100%;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
	// font-family: 'Montserrat', sans-serif;
	
	/*background: rgb(174,180,238);*/
	min-height: 100%;
	// background: radial-gradient(circle, rgba(174,180,238,1) 0%, rgba(250,152,5,1) 100%);

	a{text-decoration:none;}
	.txt-rt{text-align:right;}/* text align right */
	.txt-lt{text-align:left;}/* text align left */
	.txt-center{text-align:center;}/* text align center */
	.float-rt{float:right;}/* float right */
	.float-lt{float:left;}/* float left */
	.clear{clear:both;}/* clear float */
	.pos-relative{position:relative;}/* Position Relative */
	.pos-absolute{position:absolute;}/* Position Absolute */
	.vertical-base{	vertical-align:baseline;}/* vertical align baseline */
	.vertical-top{	vertical-align:top;}/* vertical align top */
	nav.vertical ul li{	display:block;}/* vertical menu */
	nav.horizontal ul li{	display: inline-block;}/* horizontal menu */
	img{max-width:100%;}

	.pattern{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.3'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		
		width:100%;
		min-height: 100%;
		
	}

	h1 {
		font-size: 40px;
		text-align: center;
		/*margin: 2.3em 0 1.5em;*/
		padding: 2.3em 0 1.5em;
		text-transform: uppercase;
		letter-spacing: 4px;
		color: #fff;
	}

	.main-agile {
		width: 28%;
		margin: 0px auto 0px auto;
		/*background: rgba(0, 0, 0, 0.22);*/
		background: rgba(147, 147, 147, 0.69);
		padding: 12px;
		position: relative;
		-webkit-box-shadow: 5px 5px 7px 0px rgba(0, 0, 0, 0.12);
		-moz-box-shadow: 5px 5px 7px 0px rgba(0, 0, 0, 0.12);
		box-shadow: 5px 5px 7px 0px rgba(0, 0, 0, 0.12);
	}

	.content-wthree {
		padding: 50px 50px;
		text-align: center;
		position: relative;
	}
	
	h2 {
		color: #fff;
		font-size: 28px;
		letter-spacing: 2px;
		text-align: center;
		font-weight: 500;
		margin-bottom: 1.3em;
		text-transform: uppercase;
	}
	.content-wthree input[type="text"], .content-wthree input[type="password"] {
		outline: none;
		font-size: 14px;
		letter-spacing: 1px;
		color: #000000;
		padding: 12px 12px 12px 40px;
		width: 85.2%;
		border: none;
		-webkit-appearance: none;
		margin-bottom: 12px;
		border: 1px solid #fff;
	}
	.content-wthree input[type="submit"] {
		font-weight: bold;
		width: 100%;
		background: #ffd768;
		border: none;
		margin: 3em 0 1.5em;
		outline: none;
		letter-spacing: 1px;
		font-size: 15px;
		padding: 14px 0;
		color: #fff;
		text-transform: uppercase;
		transition: .5s ease-in;
		-webkit-transition: .5s ease-in;
		-moz-transition: .5s ease-in;
		-o-transition: .5s ease-in;
		-ms-transition: .5s ease-in;
		cursor: pointer;
	}
	.content-wthree input[type="submit"]:hover{
		background:#ffbb00;
	}
	.inputs-w3ls i {
		position: absolute;
		left: 35px;
		top: 15px;
		font-size: 16px;
		color: #000;
	}
	.inputs-w3ls {
		position: relative;
	}
	.wthree-text a {
		color: #fff;
		font-size: 13px;
		display: inline;
		letter-spacing: 1px;
		text-transform: capitalize;
	}
	/*--//footer--*/
	.footer-w3l p{
		margin:0 auto;
		text-align:center;
		font-size:14px;
		color:#eee;
		letter-spacing:1px;
		line-height:27px;
	}
	.footer-w3l p a{
		color:#fff;
	}
	.footer-w3l p a:hover{
		text-decoration:underline;
	}
	.footer-w3l {
		margin: 65px 0px;
	}
	/*--//footer--*/
	
	/*--responsive--*/
	@media(max-width:1600px){
		.main-agile {
			width: 35%;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
		width: 88.3%;
		}
	}
	@media(max-width:1440px){
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 87.3%;
		}
		.footer-w3l {
			margin: 45px 0px;
		}
		h2 {
			font-size: 25px;
			margin-bottom: 1em;
		}
		h1 {
			font-size: 35px;
			padding: 1.7em 0 1em;
		}
		.content-wthree {
			padding: 40px 40px;
		}
	}
	@media(max-width:1366px){
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 86.3%;
		}
	}
	@media(max-width:1280px){
			.content-wthree {
			padding: 35px 35px;
		}
		h1 {
			font-size: 33px;
			padding: 1.5em 0 1em;
		}
		.content-wthree input[type="submit"] {
			margin: 2.5em 0 1.3em;
		}
		.footer-w3l {
			margin: 36px 0px;
		}
	}
	@media(max-width:1080px){
		.main-agile {
			width: 40%;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 84.9%;
		}
	}
	@media(max-width:1050px){
		.main-agile {
			width: 41%;
		}
	}
	@media(max-width:1024px){
		h1 {
			padding: 2.3em 0 1.3em;
		}
		.footer-w3l {
			margin: 55px 0px;
		}
		.main-agile {
			width: 42%;
		}
	}
	@media(max-width:991px){
		h1 {
			padding: 1.5em 0 1em;
		}
		.footer-w3l {
			margin: 36px 0px;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 84.4%;
		}
	}
	@media(max-width:900px){
		.main-agile {
			width: 47%;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 84.9%;
		}
		h2 {
			font-size: 23px;
		}
		h1 {
			padding: 1.8em 0 1.2em;
			font-size: 30px;
		}
	}
	@media(max-width:800px){
		.main-agile {
			width: 50%;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 83.5%;
		}
	}
	@media(max-width:768px){
		h1 {
			padding: 3.5em 0 1.5em;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 82.5%;
		}
		.footer-w3l {
			margin: 80px 0px;
		}
	}
	@media(max-width:767px){
		h1 {
			padding: 2.2em 0 1.2em;
		}
		.footer-w3l {
			margin: 42px 0px;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			font-size: 13px;
			padding: 10px 10px 10px 37px;
			width: 84%;
		}
		.content-wthree input[type="submit"] {
			margin: 2em 0 1.3em;
			font-size: 14px;
			padding: 13px 0;
		}
		.footer-w3l p {
			font-size: 13px;
		}
		.inputs-w3ls i {
			bottom: 23px;
		}
	}
	@media(max-width:736px){
		.main-agile {
			width: 52%;
		}
	}
	@media(max-width:667px){
		.content-wthree {
			padding: 35px 30px;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 83%;
		}
	}
	@media(max-width:640px){
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 82%;
		}
	}
	@media(max-width:600px){
		.main-agile {
			width: 60%;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 83.5%;
		}
	}
	@media(max-width:568px){
		h1 {
			padding: 2.5em 0 1.5em;
			font-size: 28px;
		}
		h2 {
			font-size: 20px;
		}
		.main-agile {
			width: 63%;
		}
	}
	@media(max-width:480px){
		h1 {
			font-size: 25px;
		}
		.content-wthree {
			padding: 30px 25px;
		}
		.main-agile {
			width: 70%;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 83%;
		}
		.footer-w3l {
			margin: 35px 0px;
		}
		.footer-w3l p {
			line-height: 33px;
		}
	}
	@media(max-width:440px){
		.footer-w3l p {
			padding: 0 25px;
		}
	}
	@media(max-width:414px){
		.footer-w3l {
			margin: 35px 20px;
		}
		.main-agile {
			width: 80%;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 82.7%;
		}
		.footer-w3l p {
			padding: 0px;
		}
	}
	@media(max-width:384px){
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 81%;
		}
		h1 {
			letter-spacing: 3px;
			padding: 2em 0 1.5em;
		}
		.footer-w3l {
			margin: 35px 24px;
		}
	}
	@media(max-width:375px){
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 80.6%;
		}
		h1 {
			padding: 2.3em 0 1.5em;
			font-size: 24px;
		}
		.inputs-w3ls i {
		font-size: 15px;
		}
	}
	@media(max-width:320px){
		h1 {
			padding: 1.6em 0 1.2em;
			font-size: 22px;
			letter-spacing: 2px;
		}
		h2 {
			font-size: 17px;
		}
		.content-wthree {
			padding: 20px 15px;
		}
		.footer-w3l {
			margin: 27px 22px;
		}
		.content-wthree input[type="text"], .content-wthree input[type="password"] {
			width: 78%;
		}
		.footer-w3l p {
			line-height: 27px;
		}
	}
}